<template>
  <div class="primary-banner bg-darkBluePrimary xl:h-[429px] max-xl:h-auto">
    <div class="lg:mb-20">
      <!-- eslint-disable vue/no-v-html -->
      <h3
        class="
          text-white
          !leading-normal
          font-semibold
          2xl:text-[40px]
          lg:text-4xl
          md:text-4xl
          sm:text-3xl
          max-sm:text-4xl
          2xl:w-[85%]
          xl:w-[95%]
          lg:w-[90%]
          w-[70%]
        "
        v-html="sanitizeHtml(_title)"
      />
      <p class="text-base w-full text-white pt-2 max-lg:my-5">Vietnam Trade Facilitation Hub (VNTF)</p>
      <!--eslint-enable-->
    </div>
    <div class="flex lg:w-full lg:pt-8 gap-4 justify-start max-xl:flex-wrap max-sm:pb-4">
      <div class="flex gap-4">
        <a
          :href="route('policy-and-procedures.Procedures', { tabActive: 2, activeCategory: 2 })"
          class="text-white font-normal w-max p-2 inline-flex border rounded text-center border-[#F49D2E] hover:scale-110 ease-out duration-300"
          >{{ $t('Quy trình xuất khẩu') }}</a
        >
        <a
          :href="route('policy-and-procedures.Procedures', { tabActive: 2, activeCategory: 3 })"
          class="text-white font-normal w-max p-2 inline-flex border rounded text-center border-[#2078CE] hover:scale-110 ease-out duration-300"
          >{{ $t('Quy trình nhập khẩu') }}</a
        >
      </div>
      <div class="flex gap-4">
        <a
          :href="route('policy-and-procedures.MarketSelections', { tabActive: 3 })"
          class="text-white font-normal w-max p-2 inline-flex border rounded text-center border-[#6ABD45] hover:scale-110 ease-out duration-300"
          >{{ $t('Lựa chọn thị trường') }}</a
        >
        <a
          :href="route('policy-and-procedures.MarketSelections', { tabActive: 3 })"
          class="text-white font-normal w-max p-2 inline-flex border rounded text-center border-[#F49D2E] hover:scale-110 ease-out duration-300"
          >{{ $t('Thông tin hữu ích') }}</a
        >
      </div>
    </div>
    <div class="flex lg:w-full max-md:pt-2 pt-3 gap-4 justify-start max-xl:flex-wrap max-xl:pb-4">
      <div class="flex gap-4">
        <a
          :href="route('news.list')"
          class="text-white font-normal w-max p-2 inline-flex border rounded text-center border-[#2078CE] hover:scale-110 ease-out duration-300"
          >{{ $t('Tin tức cập nhật') }}</a
        >
        <a
          :href="route('forum.list')"
          class="text-white font-normal w-max p-2 inline-flex border rounded text-center border-[#6ABD45] hover:scale-110 ease-out duration-300"
          >{{ $t('Diễn đàn thảo luận') }}</a
        >
      </div>
      <div class="flex gap-4">
        <a
          :href="route('experts.list')"
          class="text-white font-normal w-max p-2 inline-flex border rounded text-center border-[#F49D2E] hover:scale-110 ease-out duration-300"
          >{{ $t('Hỏi đáp chuyên gia') }}</a
        >
        <a
          :href="route('question.list')"
          class="text-white font-normal w-max p-2 inline-flex border rounded text-center border-[#2078CE] hover:scale-110 ease-out duration-300"
          >{{ $t('Hỏi đáp bộ ngành') }}</a
        >
      </div>
      <div class="flex gap-4">
        <a
          :href="route('qa-library.list')"
          class="text-white font-normal w-max p-2 inline-flex border rounded text-center border-[#6ABD45] hover:scale-110 ease-out duration-300"
          >{{ $t('Thư viện hỏi đáp') }}</a
        >
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'

let _title = ref('Cổng thông tin doanh nghiệp <br/> về chính sách tạo thuận lợi thương mại')
</script>
<script>

import sanitizeHtml from '@/plugins/sanitizeHtml'

export default {
  methods: {
    sanitizeHtml,
  }
}
</script>


<style scoped>
@media (max-width: 640px) {
    .primary-banner {
        padding: 1.5rem;
    }
}
</style>
